<script setup lang="ts">
import {
  buildUrlPrefix,
  getProductName,
  getProductRoute,
  getProductFromPrice,
} from "@shopware/helpers";
import { RouterLink } from "vue-router";
import SwListingProductPrice from "~/components/product/SwListingProductPrice.vue";
import { defu } from "defu";
import { useCmsTranslations } from "@shopware/composables";
import { computed, ref } from "vue";
import { useNotifications, useUrlResolver } from "#imports";
const { getUrlPrefix } = useUrlResolver();
const { pushSuccess, pushError, pushWarning } = useNotifications();
const { t } = useI18n();
const { getErrorsCodes } = useCartNotification();
const { resolveCartError } = useCartErrorParamsResolver();
const props = defineProps<{
  product: any;
}>();
const { product } = props;

type Translations = {
  product: {
    addedToCart: string;
    addToCart: string;
    details: string;
    badges: {
      topseller: string;
    };
  };
};

let translations: Translations = {
  product: {
    addedToCart: "has been added to cart.",
    addToCart: "Add to cart",
    details: "Details",
    badges: {
      topseller: "Tip",
    },
  },
};

translations = defu(useCmsTranslations(), translations) as Translations;

const isSale = computed(() => {
  return (
    product &&
    product.calculatedPrice &&
    product.calculatedPrice.listPrice &&
    product.calculatedPrice.listPrice.discount < 0
  );
});

const isBestPrice = computed(() => {
  return (
    product.tagIds &&
    product.tagIds.includes("019474d44f8976dcb4d544a70df761da")
  );
});

const isHit = computed(() => {
  return (
    product.tagIds &&
    product.tagIds.includes("01929aabd1c7794394a95b6e0c5891fe")
  );
});

const isLimitedEdition = computed(() => {
  return (
    product.tagIds &&
    product.tagIds.includes("01929aac67377f62a7c5837237ba4ba7")
  );
});

const fromPrice = getProductFromPrice(props.product);

const addToCartVisible = ref(false);
const selectedVariant = ref<Schemas["Product"] | null>(null);
const updateSelectedVariant = (variant: any) => {
  selectedVariant.value = variant;
};
const isAddToCartProcessing = ref(false);
const miniCartModal = useMiniCartModal();
const addToCartProxy = async () => {
  isAddToCartProcessing.value = true;
  const { addToCart } = useAddToCart(
    product?.value?.childCount > 0 || product?.parentId
      ? selectedVariant.value
      : product
  );
  await addToCart();
  const errors = getErrorsCodes();

  errors?.forEach((element) => {
    const { messageKey, params } = resolveCartError(element);
    pushError(t(`errors.${messageKey}`, params as Record<string, unknown>));
    isAddToCartProcessing.value = false;
  });

  if (!errors.length)
    pushSuccess(
      `${props.product?.translated.name} ${translations.product.addedToCart}`
    );
  isAddToCartProcessing.value = false;
  addToCartVisible.value = false;
  miniCartModal.open();
};
</script>

<template>
  <div class="slide-card">
    <div class="w-full mb-3 relative">
      <figure class="d-block w-full h-[400px] m-0 relative">
        <RouterLink
          :to="buildUrlPrefix(getProductRoute(product), getUrlPrefix())"
          class="overflow-hidden"
        >
          <NuxtImg
            ref="imageElement"
            loading="lazy"
            format="webp"
            width="308"
            :src="product.cover?.media.url"
            :alt="getProductName({ product }) || ''"
            class="img-cover"
            data-testid="product-box-img"
          />
        </RouterLink>
        <client-only>
          <SharedWishlist
            :product="product"
            class="fav-icon top-[10px] end-[10px] absolute text-black03 text-xl"
          />
          <div class="absolute bottom-0 lg:top-3 left-0 lg:left-2">
            <div
              v-if="isHit"
              class="font-bold bg-pink text-white text-center rounded-sm text-sm px-5"
            >
              {{ $t("badge.hit") }}
            </div>
            <div
              v-else-if="isBestPrice"
              class="font-bold bg-black text-white text-center rounded-sm text-sm px-5"
            >
              {{ $t("badge.bestprice") }}
            </div>
            <div
              v-else-if="isSale"
              class="font-bold bg-red-600 text-white text-center rounded-sm text-sm px-5"
            >
              {{ $t("badge.sale") }}
            </div>
            <div
              v-else-if="product.isNew"
              class="font-bold bg-white text-black text-center rounded-sm text-sm px-5"
            >
              {{ $t("badge.new") }}
            </div>
            <div
              v-else-if="isLimitedEdition"
              class="font-bold bg-black03 text-white text-center rounded-sm text-sm px-5"
            >
              {{ $t("badge.limited") }}
            </div>
          </div>
        </client-only>
      </figure>
      <button
        type="button"
        class="absolute z-10 right-2 bottom-2 md:flex btn-produ-slide items-center justify-center btn-outline text-nowrap p-0 h-[34px] w-[34px] md:h-[40px] md:w-[40px] text-lg"
        data-testid="add-to-cart-button"
        @click="addToCartVisible = !addToCartVisible"
      >
        <i
          class="fa-solid fa-plus text-[14px] md:text-[18px]"
          :class="{ 'rotate-45': addToCartVisible }"
        ></i>
      </button>
      <RouterLink
        :to="buildUrlPrefix(getProductRoute(product), getUrlPrefix())"
        class="absolute start-0 top-0 w-full h-full flex items-center justify-center overlay-hover uppercase text-white hidden md:flex"
        style="background-color: rgba(0, 0, 0, 0.3)"
      >
        {{ $t("button.buynow.listing") }}
        <i class="fa-solid fa-angle-right"></i>
      </RouterLink>
    </div>
    <div class="pt-1">
      <RouterLink
        :to="buildUrlPrefix(getProductRoute(product), getUrlPrefix())"
        class="font-bold text-sm md:text-lg hover:text-pink block pe-[15px] truncate"
      >
        {{ product.translated.name }}
      </RouterLink>
      <div class="uppercase flex bg-white z-10 pe-[15px] ps-0">
        <SwListingProductPrice
          :product="product"
          class="flex justify-start justify-items-start gap-2 flex-row-reverse"
          data-testid="product-box-product-price"
        />
      </div>
    </div>
    <Teleport to="body">
      <Transition name="slide-up">
        <div
          v-if="addToCartVisible"
          class="fixed top-0 w-full h-full z-[45] bg-[#09090961]"
          @click="addToCartVisible = false"
        >
          <div
            class="fixed bottom-0 left-0 right-0 bg-white min-h-[100px] border-t border-gray-200 p-4 z-50 sticky-add-to-cart w-full"
            @click.stop
          >
            <div
              class="container px-0 md:px-4 mx-auto flex flex-col md:flex-row items-start md:items-center justify-between w-full gap-4"
            >

              <!-- Loader -->
              <div v-if="!selectedVariant" class="flex justify-center items-center min-h-[100px]">
                <i class="fa-solid fa-spinner animate-spin text-2xl"></i>
              </div>

              <!-- Variant Configurator -->
              <div class="w-full md:w-auto"
                v-show="selectedVariant"
              >
                <ProductDetailListingVariantConfigurator
                  @change="updateSelectedVariant"
                  :productId="
                    product.childCount
                      ? product.id
                      : (product.parentId ?? product.id)
                  "
                  :isMain="product.parentId ? false : true"
                />
              </div>

              <!-- Add to Cart Button -->
              <div class="w-full md:w-auto justify-end md:justify-start mt-3">
                <button
                  type="button"
                  class="btn_secondary w-full md:w-auto md:min-w-[310px]"
                  :class="{
                    'opacity-50 cursor-not-allowed':
                      !selectedVariant || isAddToCartProcessing,
                  }"
                  data-testid="add-to-cart-button"
                  :disabled="!selectedVariant || isAddToCartProcessing"
                  @click="addToCartProxy"
                >
                  {{ $t("product.add_to_basket") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>
